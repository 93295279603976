import $ from 'jquery';
declare let Swiper: any;

$(function () {
  $('.js-accordion__item').hide();
  $('.js-accordion').on('click', function () {
    $(this).next('.js-accordion__item').slideToggle('slow');
    $(this).children('label').toggleClass('active');
    return false;
  });
  
  $('.js-faqList__question').on('click', function () {//よくある質問 回答表示のアコーディオン
    $(this).next('.js-faqList__answer').slideToggle('slow');
    $(this).toggleClass('is-active');
    return false;
  });
  //top_MV スライドショー
  const sliderFull = new Swiper('.js-slider--topMv', {
    slidesPerView: 1,
    loop: true,
    speed: 600,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    }
  });
  //top_infomation スライドショー
  const sliderInfo = new Swiper('.js-slider--info', {
    slidesPerView: 1,
    loop: true,
    speed: 600,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    autoHeight: true,
    navigation: {
      nextEl: '.swiper-next--info',
      prevEl: '.swiper-prev--info',
    }
  });

  //top_request スライドショー
  const sliderReq = new Swiper('.js-slider--req', {
    slidesPerView: 1,
    loop: true,
    speed: 600,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    },
    // autoHeight: true,
    navigation: {
      nextEl: '.swiper-next--req',
      prevEl: '.swiper-prev--req',
    }
  });
  //top_feature スライドショー
  const sliderFeature = new Swiper('.js-slider--feature', {
    slidesPerView: 2,
    spaceBetween: 10,
    loop: true,
    speed: 600,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    autoHeight: true,
    navigation: {
      nextEl: '.swiper-next--feat',
      prevEl: '.swiper-prev--feat',
    }
  });
  //top_bannerArea スライドショー
  const sliderBannerArea = new Swiper('.js-slider--bannerArea', {
    slidesPerView: 1,
    loop: true,
    speed: 600,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    // autoHeight: true,
    navigation: {
      nextEl: '.swiper-next--bannerArea',
      prevEl: '.swiper-prev--bannerArea',
    },
  });
  //voiceArea スライドショー
  const sliderVoiceArea = new Swiper('.js-slider--voiceArea', {
    slidesPerView: 1,
    loop: true,
    hideOnClick: false,
    speed: 600,
    freeMode: true,
    freeModeMomentumRatio: 0.3,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.swiper-next--voiceArea',
      prevEl: '.swiper-prev--voiceArea',
    },
  });
});